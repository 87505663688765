<template>
  <div class="wrapper">
    <div class="form">

        <div class="form__title">Rhymakr</div>
        <el-input class="form__item" placeholder="Username" v-model="form.username"></el-input>
        <el-input class="form__item" placeholder="Password" v-model="form.password" show-password></el-input>
        <div class="form__actions-wrapper">
          <el-button class="form__actions-submit" type="primary">Submit</el-button>
        </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return{
      form: {
        username: '',
        password: ''
      }
    }

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1;
}
.form{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  padding: 100px 80px;
  background-color: #f9fafb;
  border-radius: 16px;
  position: relative;


  &__title{
    font-size: 64px;
    margin-bottom: 100px;
    text-align: center;
    color: #05445E;
  }

  &__item{
    margin: 15px 0;
  }

  &__actions-wrapper{
    margin: 80px 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__actions-submit{
    width: 100%;
    max-width: 250px;
    background-color: #05445E;
    border: none !important;
  }
}

.form::after{
  background:url("~@/src/assets/shapes/blue-shape.png") no-repeat;
  background-size: cover;
  background-position: center;
  content: "";
  top: 55%;
  left: -45%;
  width: 100%;
  height: 100%;
  max-height: 350px;
  position: absolute;
  z-index: -1;
}

.form::before{
  background:url("~@/src/assets/shapes/lightblue-shape.png") no-repeat;
  background-size: cover;
  background-position: center;
  content: "";
  top: -10%;
  left: 45%;
  width: 100%;
  height: 100%;
  max-height: 350px;
  position: absolute;
  z-index: -1;
}

</style>
